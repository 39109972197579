/* * {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  color: #444;
  background: linear-gradient(to bottom right, #67b26f, #4ca2cd);
  height: 100vh;
  padding: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}





.container {
  text-align: center;
}

.encabezado {
  grid-column: 1 / span 2;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 2rem;
} */

 /* .form-control {
    border: none;
    margin: 30px;
    padding: 7px 30px;
    font-size: 15px;
    font-family: inherit;
    text-align: center;
    width: 600px;
    border-radius: 20px; 
    margin-right: 1rem;
    color: inherit;
    border: 1px solid #fff;
    transition: all 0.3s;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
} */

/*
li {
    list-style-type: "";
}

*:focus {
    outline: none;
} */

/* Invoice */

/* body {
    font-family: "Poppins", sans-serif;
    text-align: center;
    color: rgb(31, 31, 31);
    background: #f3f3f3;
} */

/* body h1 {
    font-weight: 300;
    margin-bottom: 0px;
    padding-bottom: 0px;
    color: #000;
}

body h3 {
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 20px;
    font-style: italic;
    color: #555;
}

body a {
    color: #06f;
} */

.invoice-box {
    max-width: 1000px;
    margin: auto;
    padding: 30px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    /* font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif; */
    color: #555;
    background: #fff;
}

.invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
    border-collapse: collapse;
}

.invoice-box table td {
    padding: 5px;
    vertical-align: top;
}

.invoice-box table tr td:nth-child(1) {
    width: 5px;
}

.invoice-box table tr td:nth-child(2) {
    text-align: right;
    width: 30px;
}


.invoice-box table tr td:nth-child(3) {
    text-align: center;
    width: 30px;
}

.invoice-box table tr td:nth-child(4) {
    text-align: left;
    width: 30px;
}

.invoice-box table tr td:nth-child(5) {
    text-align: left;
    width: 600px;
}

.invoice-box table tr td:nth-child(6) {
    text-align: right;
}

.invoice-box table tr td:nth-child(7) {
    text-align: right;
    width: 5px;
}

.invoice-box table tr td:nth-child(8) {
    text-align: right;
    width: 5px;
}

.invoice-box table tr.top table td {
    padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
}

.invoice-box table tr.information table td {
    padding-bottom: 40px;
}

.invoice-box table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}

.invoice-box table tr.details td {
    padding-bottom: 20px;
}

.invoice-box table tr.item td {
    border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
    border-bottom: none;
}

.invoice-box table tr.total td:nth-child(3) {
    border-top: 2px solid #eee;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .invoice-box table tr.top table td {
        width: 100%;
        display: block;
        text-align: center;
    }

    .invoice-box table tr.information table td {
        width: 100%;
        display: block;
        text-align: center;
    }

    .form-control {
        width: 300px;
    }
}



.magic-search-bar {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    padding: 1rem;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    max-width: 1000px;
    border-radius: 10px;
    background: linear-gradient(324deg, #7d49d3, #5bbbe8, #47ce70);
    background-size: 600% 600%;
    -webkit-animation: AnimationName 5s ease infinite;
    -moz-animation: AnimationName 5s ease infinite;
    animation: AnimationName 5s ease infinite;
}

/* site to edit live gradient: https: //www.gradient-animator.com/ */

@-webkit-keyframes AnimationName {
    0% {background-position: 29% 0%}
    50% {background-position: 72% 100%}
    100% {background-position: 29% 0%}
}

@-moz-keyframes AnimationName {
    0% {background-position: 29% 0%}
    50% {background-position: 72% 100%}
    100% {background-position: 29% 0%}
}

@keyframes AnimationName {
    0% {background-position: 29% 0%}
    50% {background-position: 72% 100%}
    100% {background-position: 29% 0%}
}

.divider {
    position: relative;
    margin-top: 20px;
    margin-bottom: 60px;
    height: 1px;
}

.dividerBotton {
    position: relative;
    margin-top: 30px;
    margin-bottom: 20px;
    height: 1px;
}

.div-transparent:before {
    content: "";
    position: absolute;
    top: 0;
    left: 5%;
    right: 5%;
    width: 90%;
    height: 1px;
    background-image: linear-gradient(to right, transparent, rgb(184, 185, 187), transparent);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}